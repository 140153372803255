#navbar-main {
  padding: 10px 8% !important;
  font-family: Helvetica-Light;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 9999;
  min-height: 84px;
  & ul {
    & li {
      margin-right: 30px;
      &:last-child {
        margin: 0px;
      }
      & a {
        color: var(--base_color);
        font-size: 15px;
      }
    }
  }
  & .login_sign_up {
    padding: 0px;
    list-style: none;
    margin: 0px;
    margin-left: 3%;
    & li {
      margin-right: 10px;
    }
    & a {
      // text-transform: uppercase;
      width: auto;
      height: auto;
      opacity: 1;
      // border-radius: 1px;
      // --base_colo2
      // background: var(--base_color);
      // background: var(--base_color);
      display: -webkit-inline-box;
      display: inline-flex;
      font-size: 15px;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      color: var(--base_color);
      margin-right: 0;
      padding: 10px 20px;
      font-weight: 700;
      & i {
        margin-right: 6px;
      }
    }
    & li:nth-child(2) {
      & a {
        // background:var(--base_color2);
        // border:1px solid var(--base_color2);
        // &:hover{
        //     background:var(--base_color);
        //     border:1px solid var(--base_color);
        // }
        &:hover {
          // border-bottom: 4px solid var(--base_color);
          color: var(--base_color2) !important;
        }
      }
    }
    & li:first-child {
      & a {
        // background:#fff;
        // border:1px solid var(--base_color2);
        // color:var(--base_color2);
        // &:hover{
        //     color:var(--base_color);
        //     border:1px solid var(--base_color);

        // }
        &:hover {
          // border-bottom: 4px solid var(--base_color);
          color: var(--base_color2) !important;
        }
      }
    }
  }
  @media (max-width: 767px) {
    padding: 10px 15px !important;
    position: relative !important;
    & .navbar-brand {
      & img {
        height: 50px;
      }
    }
  }
}

.navbar-nav {
  .nav-link {
    font-size: $navbar-nav-link-font-size;
    font-family: $navbar-nav-link-font-family;
    font-weight: $navbar-nav-link-font-weight;
    text-transform: $navbar-nav-link-text-transform;
    letter-spacing: $navbar-nav-link-letter-spacing;
    @include transition($navbar-transition);

    .nav-link-inner--text {
      margin-left: 0.25rem;
    }
  }
}

.navbar-brand {
  font-size: $font-size-sm;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 0.05px;

  img {
    height: 62px;
  }
}

.navbar-brand-georgia {
  font-size: $font-size-sm;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 0.05px;

  img {
    height: 100px;
  }
}

.navbar-dark {
  .navbar-brand {
    color: $white;
  }
}

.navbar-light {
  .navbar-brand {
    color: $gray-800;
  }
}

.navbar-nav {
  .nav-item {
    .media:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  // .navbar-main {
  //     padding: 1rem 0;
  // }

  .navbar-nav {
    .nav-item {
      margin-right: 0.5rem;

      [data-toggle='dropdown']::after {
        transition: $transition-base;
      }

      &.show {
        [data-toggle='dropdown']::after {
          transform: rotate(180deg);
        }
      }
    }
    .nav-link {
      padding-top: $navbar-nav-link-padding-y;
      padding-bottom: $navbar-nav-link-padding-y;
      border-radius: $navbar-nav-link-border-radius;

      i {
        margin-right: 0.625rem;
      }
    }

    .nav-link-icon {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
      font-size: 1rem;
      border-radius: $navbar-nav-link-border-radius;

      i {
        margin-right: 0;
      }
    }

    // Dropdown menu
    .dropdown-menu {
      opacity: 0;
      pointer-events: none;
      margin: 0;

      &:before {
        background: $dropdown-bg;
        box-shadow: none;
        content: '';
        display: block;
        height: 16px;
        width: 16px;
        left: 20px;
        position: absolute;
        bottom: 100%;
        transform: rotate(-45deg) translateY(1rem);
        z-index: -5;
        border-radius: $border-radius-sm;
      }
    }

    .dropdown-menu-right {
      &:before {
        right: 20px;
        left: auto;
      }
    }

    &:not(.navbar-nav-hover) {
      .dropdown-menu {
        &.show {
          opacity: 1;
          pointer-events: auto;
          animation: show-navbar-dropdown 0.25s ease forwards;
        }

        &.close {
          display: block;
          animation: hide-navbar-dropdown 0.15s ease backwards;
        }
      }
    }

    &.navbar-nav-hover {
      .dropdown-menu {
        opacity: 0;
        display: block;
        pointer-events: none;
        transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
        transition:
          visibility 0.25s,
          opacity 0.25s,
          transform 0.25s;
      }

      .nav-item.dropdown:hover > .dropdown-menu {
        display: block;
        opacity: 1;
        pointer-events: auto;
        visibility: visible;
        transform: translate(0, 0);
        animation: none;
      }
    }

    .dropdown-menu-inner {
      position: relative;
      padding: 1rem;
    }
  }
}

// Transparent navbar
.navbar-transparent {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: transparent;
  border: 0;
  box-shadow: none;

  .navbar-brand {
    color: rgba(255, 255, 255, 1);
  }

  .navbar-toggler {
    color: rgba(255, 255, 255, 1);
  }

  .navbar-toggler-icon {
    //White navigation bar for mobile
    // background-image: $navbar-dark-toggler-icon-bg;
    //Black navigation bar for mobile
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath stroke="rgba(0, 0, 0, 0.95)" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/%3E%3C/svg%3E');
  }
}

@include media-breakpoint-up(md) {
  .navbar-transparent {
    .navbar-nav {
      .nav-link {
        // color: $navbar-dark-color;
        color: #172b4d !important;

        @include hover-focus {
          // color: $navbar-dark-hover-color;
          color: #172b4d !important;
        }

        &.disabled {
          color: $navbar-dark-disabled-color;
          // color: #172b4d!important;
        }
      }

      .show > .nav-link,
      .active > .nav-link,
      .nav-link.show,
      .nav-link.active {
        color: $navbar-dark-active-color;
      }
    }

    .navbar-brand {
      color: $navbar-dark-color;

      @include hover-focus {
        color: $navbar-dark-color;
      }
    }
  }
}

// Collapse

.navbar-collapse-header {
  display: none;
}

@include media-breakpoint-down(md) {
  .navbar-nav {
    .nav-link {
      padding: 0.625rem 0;
      color: theme-color('default') !important;
    }

    .dropdown-menu {
      box-shadow: none;
      min-width: auto;

      .media {
        svg {
          width: 30px;
        }
      }
    }
  }

  .navbar-collapse {
    width: calc(100% - 1.4rem);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1050;
    margin: 0.7rem;
    overflow-y: auto;
    height: auto !important;
    opacity: 0;

    .navbar-toggler {
      width: 20px;
      height: 20px;
      position: relative;
      cursor: pointer;
      display: inline-block;
      padding: 0;

      span {
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        opacity: 1;
        background: #283448;
      }

      :nth-child(1) {
        transform: rotate(135deg);
      }

      :nth-child(2) {
        transform: rotate(-135deg);
      }
    }

    .navbar-collapse-header {
      display: block;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      // border-bottom: 1px solid rgba(0, 0, 0, .1);
    }

    .collapse-brand {
      img {
        height: 36px;
      }
    }

    .collapse-close {
      text-align: right;
    }
  }

  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    padding: 1.5rem;
    border-radius: $border-radius;
    background: #fff;
    box-shadow:
      0 50px 100px rgba(50, 50, 93, 0.1),
      0 15px 35px rgba(50, 50, 93, 0.15),
      0 5px 15px rgba(0, 0, 0, 0.1);
    animation: show-navbar-collapse 0.2s ease forwards;
  }

  .navbar-collapse.collapsing-out {
    animation: hide-navbar-collapse 0.2s ease forwards;
  }
}

// Keyframes

@keyframes show-navbar-collapse {
  0% {
    opacity: 0;
    transform: scale(0.95);
    transform-origin: 100% 0;
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hide-navbar-collapse {
  from {
    opacity: 1;
    transform: scale(1);
    transform-origin: 100% 0;
  }

  to {
    opacity: 0;
    transform: scale(0.95);
  }
}

@keyframes show-navbar-dropdown {
  0% {
    opacity: 0;
    transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
    transition:
      visibility 0.25s,
      opacity 0.25s,
      transform 0.25s;
  }

  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes hide-navbar-dropdown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate(0, 10px);
  }
}
