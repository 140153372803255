/* You can add global styles to this file, and also import other style files */
:root {
  --base_color: #111f43;
  --base_color2: #f79333;
}

.text-center {
  text-align: center !important;
}

.sans {
  font-family: Helvetica;
}

body,
html {
  overflow-x: hidden;
}

@import '../node_modules/ag-grid-community/src/styles/ag-grid.scss';
@import '../node_modules/ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss';
@import url('https://fonts.googleapis.com/css2?family=K2D:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import 'stream-chat-angular/src/assets/styles/scss/index.scss';
div.shape-container,
div.shape-container > .p-t-6 {
  padding-top: 0 !important;
}
owl-carousel-o {
  & .owl-carousel {
    & .center {
      left: 0 !important;
    }
  }
}
.slider_container {
  & button {
    border: none;
    background: none;
    padding: 0px;
    margin: 0px;
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;
    display: none;
    &.prev {
      left: auto;
      right: 0px;
    }
  }
  & .slider_container_owl {
    position: relative;
    margin: auto;
  }
}

.logo_slider {
  & owl-stage {
    display: block;
    margin: auto;
    overflow: hidden;
  }
  & .logo_img {
    margin: 15px;
  }
  & .owl-nav {
    width: 113%;
    left: -70px;
  }
}
.owl-carousel .owl-stage:after {
  content: '.';
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 5px;
}
.owl-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 116%;
  left: -88px;
  display: flex;
  justify-content: space-between;
  margin: 0;
  z-index: -1;
  & .owl-prev {
    background: url('assets/home-new/arrow_left.png') !important;
    width: 48px;
    height: 48px;
  }
  & .owl-next {
    background: url('assets/home-new/arrow_right.png') !important;
    width: 48px;
    height: 48px;
  }
  & [class*='owl-'] {
    border: none;
    padding: 0px;
    background: none;
    margin: 0 !important;
  }
  @media (max-width: 1024px) {
    display: none !important;
  }
}

.top_slider_holder {
  & .owl-nav {
    z-index: 9;
    width: 95%;
    left: 0;
    right: 0;
    margin: auto;
    & .owl-next {
      width: 48px;
      height: 48px;
    }
    & [class*='owl-'] {
      border: none;
      padding: 0px;
      background: none;
      margin: 0 !important;
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAA3ElEQVR4nO2bsUmEQRQGBxGEK0aDszwP1BpMbMnrw0hsQGEMRPwDA8FzN9iZAh7fDGy4EBERf0d9UI/q1ewtw1Ev1Dc/eVk1wo3fLBvhdhPhVb2evWk4RaAIQBGAIgBFAIoAFAEoAlAEoAgAqHdFKEIRgCIARQCKABQBKAJQBKAIQBGAIgCg3m8iPKvnp7h7dooj8c/88AT2szcNI/nkk09+9qZhJJ988snP3jSM5JNPPvnZm4aRfPLJJz970zCSX1j+sJFf68OEulPfl5T/Qn1Un9TL2VsiIuKXfABTVcbd1b7rVwAAAABJRU5ErkJggg==') !important;
      background-size: contain !important;
      font-size: 0px !important;
      filter: drop-shadow(0px 0px 3px #000);
      &.owl-prev {
        transform: rotate(180deg);
      }
    }
  }
}

.btn-custom {
  border-radius: 30px;
  height: 60px;
  font-size: 22px;
  color: #ffffff !important;
  font-weight: 700;
  display: inline-flex;
  background: var(--base_color2);
  min-width: 228px;
  align-items: center;
  justify-content: center;
  &.btn-bordered {
    border: 1px solid var(--base_color);
    color: var(--base_color) !important;
    background: none;
    text-transform: uppercase;
  }
}

.ag-theme-alpine {
  @include ag-theme-alpine();
}

.pointer {
  cursor: pointer !important;
}

@font-face {
  font-family: 'Helvetica-Light';
  font-style: normal;
  font-weight: normal;
  src:
    local('Helvetica-Light'),
    url('./assets/vendor/fonts_latest/Helvetica-Light.ttf') format('woff');
}

@font-face {
  font-family: 'Helvetica-Bold';
  font-style: normal;
  font-weight: normal;
  src:
    local('Helvetica-Bold'),
    url('./assets/vendor/fonts_latest/Helvetica Bold.ttf') format('woff');
}

@font-face {
  font-family: 'Avocado Sans Bold';
  font-style: normal;
  font-weight: normal;
  src:
    local('Avocado Sans Bold'),
    url('./assets/vendor/fonts_latest/Avocado Sans Bold.ttf') format('woff');
}

// @font-face {
//   font-family: "Helvetica-Light";
//   font-style: normal;
//   font-weight: normal;
//   src: local("Helvetica-Light"),
//   url("./assets/vendor/font-awesome/fonts/Quicksand/static/Helvetica-Light.ttf") format("woff");
// }

// @font-face {
//   font-family: "Helvetica Bold";
//   font-style: normal;
//   font-weight: normal;
//   src: local("Helvetica Bold"),
//   url("./assets/vendor/font-awesome/fonts/Quicksand/static/Helvetica Bold.ttf") format("woff");
// }

// @font-face {
//   font-family: "Helvetica-Light";
//   font-style: normal;
//   font-weight: normal;
//   src: local("Helvetica-Light"), url("./assets/vendor/font-awesome/fonts/Nunito/Helvetica-Light.ttf") format("woff");
// }

// @font-face {
//   font-family: "Helvetica Bold";
//   font-style: normal;
//   font-weight: normal;
//   src: local("Helvetica Bold"), url("./assets/vendor/font-awesome/fonts/Nunito/Helvetica Bold.ttf") format("woff");
// }

// @font-face {
//   font-family: "Helvetica-Light";
//   font-style: normal;
//   font-weight: normal;
//   src: local("Helvetica-Light"), url("./assets/vendor/font-awesome/fonts/Nunito/Helvetica-Light.ttf") format("woff");
// }

/* Margin starts */
.m-t-5 {
  margin-top: 5px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-t-7 {
  margin-top: 7%;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-l-15 {
  margin-left: 15px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-l-50 {
  margin-left: 50px;
}

.m-l-100 {
  margin-left: 100px;
}

.m-l-90 {
  margin-left: 90px;
}

.m-l-75 {
  margin-left: 75px;
}

.m-l-70 {
  margin-left: 70px;
}

.m-r-0 {
  margin-right: 0px !important;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-r-50 {
  margin-right: 50px;
}

.m-r-70 {
  margin-right: 70px;
}

.m-r-75 {
  margin-right: 75px;
}

.m-r-90 {
  margin-right: 90px;
}

.m-r-100 {
  margin-right: 100px;
}

.m-r-200 {
  margin-right: 200px;
}

.m-l-200 {
  margin-left: 200px;
}

.m-l-150 {
  margin-left: 150px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-t-60 {
  margin-top: 60px;
}

.m-t-80 {
  margin-top: 80px;
}

.m-t-150 {
  margin-top: -150px !important;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-60 {
  margin-bottom: 60px;
}

/* Margin Ends */

/* Padding Starts */
.p-l-0 {
  padding-left: 0px !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.p-l-50 {
  padding-left: 50px !important;
}

.p-r-0 {
  padding-right: 0px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.p-t-6 {
  padding-top: 6rem;
}

.p-t-15 {
  padding-top: 15px;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-15 {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-top: 15px;
}

.p-t-30 {
  padding-top: 30px;
}

.p-t-6 {
  padding-top: 6rem !important;
}

.p-b-0 {
  padding-bottom: 0px !important;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-b-30 {
  padding-bottom: 30px;
}

.m-l-r-0 {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

/* Padding Ends */
.orange-bg {
  position: absolute;
  z-index: 1;
}

.icon-bg {
  position: relative;
  z-index: 2;
}

.float-right {
  float: right;
}

.text-right {
  text-align: right !important;
}

.orange-text {
  color: #e87722 !important;
}

.black-text {
  color: #000000 !important;
}

.blue-text {
  /* color: #003865 !important; */
  color: #111f43 !important;
}

.dark-blue-bg {
  background: #111f43;
}

.dark-blue-text {
  color: #111f43;
}

.angular-with-newlines {
  white-space: pre-wrap;
}

.blur-bg {
  filter: blur(3px);
  -webkit-filter: blur(4px);
}

.caption-title {
  position: absolute;
  right: -0%;
  bottom: -150px;
  left: 0%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #e87722;
  text-align: center;
  font-style: bold;
  font-size: 35px;
  /* font-family: Georgia !important; */
}

.b {
  font-weight: bold !important;
}

/* No list in ul   */
.list-no {
  list-style: none;
}

.i {
  font-style: italic !important;
}

.error-border {
  border: 1px solid red !important;
}

.container {
  padding-right: 15px;
}

.border-0 {
  border: 0px !important;
}

.border {
  border: 1px solid #c1c1c1;
}

.border-1 {
  border: 1px solid #f1f1f1;
  border-radius: 5px;
}

.border-t-1 {
  border-top: 1px solid #e3e2e3;
}

.border-l-1 {
  border-left: 1px solid #e3e2e3;
}

.border-r-1 {
  border-right: 1px solid #e3e2e3;
}

.border-b-1 {
  border-bottom: 1px solid #e3e2e3;
}

.box-border {
  border: 1px solid #dddddd !important;
}

.bottom-border-dotted {
  border-bottom: 1px dotted #a9a9a9 !important;
}

.bottom-border-solid {
  border-bottom: 0.8px solid #a9a9a9 !important;
}

.hide-bullets {
  list-style-type: none;
}

.Helvetica-Light {
  /* font-family: Helvetica-Light; */
  font-family: 'Helvetica-Light';
}

.Helvetica-Bold {
  /* font-family: Helvetica Bold; */
  font-family: 'Helvetica-Bold';
}

.nunito-semibold {
  /* font-family: Helvetica Bold; */
  font-family: 'Quicksand-SemiBold';
}

.row {
  display: flex;
}

.Lcolumn {
  /* // float: left; */
  display: inline-block;
  flex: 76%;
}

a.disabled:hover,
a:disabled:hover,
button.disabled:hover,
button:disabled:hover {
  cursor: not-allowed;
}

.Rcolumn {
  /* // float: left; */
  flex: 24%;
  background-image: url('/assets/img/theme/orangebg.jpg');
  background-size: 60%;
  border-radius: 0px 25px 25px 0px;
}

.column {
  flex: 50%;
}

.hovcolor:hover {
  /* // color: $footer-link-hover-color !important; */
  /* // color: $footer-link-color !important; */
  color: #e87722 !important;
}

div.scrollbox {
  /* width: 1100px; */
  height: 450px;
  overflow: scroll;
}

.title {
  font-size: 35px;
  color: #111f43;
}

ng-multiselect-dropdown.form-control .multiselect-dropdown .dropdown-btn {
  border: 1px solid #cad1d7 !important;
  /* color: #183569 !important; */
  font-weight: 600;
}

ng-multiselect-dropdown.form-control {
  padding: 0;
  border: none;
}

ng-multiselect-dropdown.form-control .multiselect-dropdown .dropdown-btn {
  /* border-radius: 0px !important; */
  position: absolute;
  height: calc(2.75rem + 2px);
  padding: 12px 12px !important;
  right: 0;
}

ng-multiselect-dropdown.form-control .multiselect-dropdown .dropdown-list {
  margin-top: calc(2.75rem + 2px);
}

ng-multiselect-dropdown.form-control
  .multiselect-dropdown
  .dropdown-btn
  .dropdown-down {
  border-top: 5px solid black !important;
  border-left: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
}

ng-multiselect-dropdown.form-control
  .multiselect-dropdown
  .dropdown-btn
  .dropdown-up {
  border-bottom: 5px solid black !important;
  border-left: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
}

.form-control.multiselect-dropdown:focus {
  outline: none !important;
}

/* //  disable border for tiny-mce editor and used custom one */
.tox-tinymce {
  border: 1.5px solid #f79b32 !important;
}

/* // disables the tags in bottom of the tiny-mce editor */
.tox-statusbar {
  visibility: hidden !important;
}

.editor-border {
  border: 1px solid #ced4da !important;
  border-radius: 3px !important;
}

.border-0 {
  border: 0px !important;
}

.course-page {
  font-family: Helvetica;
  color: var(--base_color);
  & .container {
    margin: auto;
  }
  & .course_banner {
    background: #111f43;
    padding: 90px 0;
    & * {
      color: #fff;
    }
    & h2 {
      margin: 0px;
      margin: 0px;
      font-size: 55px;
      font-weight: 500;
    }
    & h5 {
      font-size: 30px;
      font-weight: 500;
      text-align: left;
      color: #f79333;
      margin: 15px 0;
      margin-bottom: 15px;
      margin-bottom: 30px;
    }
    & .course_deatils {
      display: grid;
      grid-template-columns: 1fr 1fr;
      font-size: 24px;
      grid-gap: 5px;
      & p {
        margin: 0px;
        font-size: 24px;
      }
    }
    & h6 {
      & em {
        font-style: normal;
      }
    }
    & .absolute_option {
      background: var(--base_color2);
      padding: 15px;
      border-radius: 10px;
      max-width: 350px;
      margin-bottom: -350px;
      & * {
        color: var(--base_color);
      }
      & .price_bottom {
        font-size: 24px;
        text-align: center;
        line-height: 31px;
        margin-top: -4px;
        & span {
          font-size: 45px;
        }
      }
      & .buttons {
        display: flex;
        flex-direction: column;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        margin: 25px 0;
        padding: 18px 0;
        & a {
          display: inline-flex;
          background: #fff;
          width: 228px;
          min-width: 80%;
          border-radius: 30px;
          padding: 14px;
          text-align: center;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          font-weight: bold;
          margin-bottom: 6px;
          font-size: 18px;
          margin: auto;
          margin-bottom: auto;
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
    .price_radio h3 {
      margin: 0px;
      font-size: 20px;
      margin: 10px 0;
    }
    .radioP {
      display: flex;
      align-content: center;
      line-height: inherit;
      margin-bottom: 10px;
    }
    .radioP input {
      appearance: none;
      border: 1px solid #fff;
      width: 15px;
      height: 15px;
      margin: 0px;
      border-radius: 100%;
      margin-right: 6px;
      position: relative;
      top: 1px;
    }
    .radioP span {
      margin-left: auto;
    }
    .radioP label {
      margin: 0px;
      display: flex;
      align-items: center;
      font-size: 18px;
    }
    .absolute_option > div img {
      height: 210px !important;
      object-fit: cover;
      border-radius: 10px;
    }
    @media (max-width: 767px) {
      padding: 20px 0;
      & h2 {
        font-size: 35px;
      }
      & h5 {
        margin: 5px 0;
        margin-bottom: 16px;
        font-size: 24px;
      }
      & .course_deatils {
        & p {
          margin: 0px;
          font-size: 18px;
        }
      }
      & .absolute_option {
        margin: 0px !important;
        margin-top: 30px !important;
      }
    }

    @media (max-width: 991px) and (min-width: 768px) {
      padding: 60px 0;
      & h2 {
        font-size: 35px;
      }
      & h5 {
        margin: 5px 0;
        margin-bottom: 16px;
        font-size: 24px;
      }
      & .course_deatils {
        & p {
          margin: 0px;
          font-size: 18px;
        }
      }
    }
  }
}

.topic_covered {
  background: #ededed;
  padding: 40px 0;
  min-height: 178px;
  & h1 {
    font-size: 30px;
    margin: 0px;
    font-weight: bold;
  }
  & .listing_course {
    & ul {
      display: flex;
      flex-wrap: wrap;
      padding: 0px;
      margin: 0px;
      & li {
        display: inline-block;
        margin-right: 6px;
        font-size: 18px;
        &:after {
          content: ',';
        }
      }
    }
  }
  @media (max-width: 767px) {
    background: #ededed;
    padding: 20px 0;
    min-height: unset;
  }
}

.course_brief {
  padding: 50px 0;
  border-bottom: 3px solid var(--base_color2);
  & h1 {
    font-size: 40px;
    font-weight: 600;
    text-align: left;
    color: #f79333;
    margin-bottom: 20px;
  }
  & p {
    font-size: 18px;
  }
  & p:last-child {
    margin: 0px;
  }
  & ul {
    padding: 0px;
    list-style: none;
    & li {
      margin-bottom: 5px;
      display: block;
      font-size: 18px;
    }
  }
}
.backimage {
  & .courses_icons {
    justify-content: space-around;
    margin-bottom: 40px;
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-items: first baseline;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      & span {
        font-weight: 500;
        text-align: center;
        font-size: 20px;
        display: block;
        margin-top: 15px;
      }
    }
    @media (max-width: 767px) {
      flex-wrap: nowrap;
      margin: 0px;
      overflow-x: scroll;
      justify-content: unset;
      & > div {
        margin-right: 20px;
        flex: 0 0 32%;
        padding-bottom: 15px;
        & span {
          font-size: 16px;
          white-space: nowrap;
        }
        & img {
          width: 70px;
        }
      }
    }
  }
  & .courses-info-holder {
    & .courses-info-box {
      background: #fff;
      color: #000;
      padding: 20px;
      border-radius: 5px;
      text-align: center;
      display: block;
      align-items: center;
      justify-content: center;
      background: #f2f2f2;
      & p {
        font-size: 16px;
        line-height: 22px;
        height: 90px;
        overflow: hidden;
        margin-bottom: 16px;
      }
      & h6 {
        font-size: 22px;
        font-weight: 700;
        color: var(--base_color);
        margin-top: 12px;
        margin-bottom: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        display: block;
      }
      & .learnMore {
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        color: #111f43;
        display: inline-block;
        margin-bottom: 18px;
      }
      & .workshops {
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        color: #111f43;
        display: inline-block;
        // margin-bottom: 18px;
      }
      & .mask_img {
        border-radius: 10px;
      }
      & .session-btn {
        & a {
          background: var(--base_color);
          border-radius: 30px;
          width: 234px;
          height: 60px;
          font-size: 18px;
          color: #fff;
          font-weight: 700;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    &.courses-info-holder_page {
      margin-top: 40px;
      margin-bottom: 70px;
      & h1 {
        font-size: 35px;
        font-weight: 400;
        text-align: left;
        color: #111f43;
        line-height: 50px;
        margin-bottom: 40px;
      }
    }
  }
}

.inner-chat {
  .nav-tabs {
    border-bottom: 0;
    background: #f7f7f7;
    margin: 10px;
    .nav-item {
      .nav-link {
        padding: 8px 20px;
        border-radius: 0px;
        min-width: 85px;
        text-align: center;
      }
      .nav-link.active {
        color: #ffffff;
        background-color: #003865;
      }
    }
  }
}

// for chat
.str-chat__main-panel {
  background: #f1f1f1;
  padding: 10px !important;
}
.str-chat.str-chat-channel {
  height: calc(100vh + -105px);
}

.str-chat .str-chat__textarea > textarea {
  background: #f1f1f1;
  padding: 20px;
}
.str-chat__input-flat-fileupload {
  position: relative;
  top: -8px;
}

.str-chat__message-inner .str-chat__message-simple__actions {
  display: none;
}
.custom-tab-content .tab-content {
  padding: 0 !important;
  // height: 60vh;
  // overflow-y: auto;
  // overflow-x: hidden;
}
.str-chat-channel-list {
  width: 100% !important;
}
.str-chat__channel-preview-messenger--name span {
  max-width: 200px;
}
.window-wrap .nav-pills .nav-link {
  color: #0e2650 !important;
}
.window-wrap .nav-pills .nav-link.active,
.window-wrap .nav-pills .show > .nav-link {
  background-color: #0e2650 !important;
  color: #fff !important;
}
.str-chat__header-livestream-left--members {
  display: none !important;
}
.str-chat__channel-preview-messenger .str-chat__channel-preview-end-first-row {
  font-size: 14px;
  font-weight: 500;
  word-break: break-word;
}
.str-chat__channel-preview-messenger--right {
  position: relative;
}
.str-chat__channel-preview-unread-badge {
  position: absolute;
  background: #ef1313;
  width: 20px;
  height: 20px;
  border-radius: 30px;
  text-align: center;
  color: #fff;
  right: -5px;
  bottom: -2px;
  font-size: 11px;
  line-height: 20px;
  font-weight: 600;
}
.str-chat__channel-list-messenger__main {
  padding: 5px !important;
}
.custom-tab-content .list {
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}
