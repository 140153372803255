.headroom {
  will-change: transform;
  // background-color: inherit;
  background-color: #ffffff;
  @include transition($transition-base);
}

.headroom-carousel {
  will-change: transform;
  // background-color: inherit;
  background-color: #dbd5d557;
  @include transition($transition-base);
}

.white-text {
  color: #ffffff !important;
}

.b {
  font-weight: bold;
}

.text-17 {
  font-size: 17px;
}

.center-text {
  text-align: center;
}

.landing-text {
  font-weight: bold;
  font-size: 70px;
}

.headroom--pinned {
  @extend .position-fixed;
  transform: translateY(0%);
}
.headroom--unpinned {
  @extend .position-fixed;
  transform: translateY(-100%);
}

.headroom--not-top {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: theme-color('white') !important;
  box-shadow: 0 1px 10px rgba(130, 130, 134, 0.1);
}
